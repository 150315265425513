import './App.css';
import banner from "./img/escuela-alcaldes-mirandadeazan.jpg";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <img src={banner} className="banner" alt="1ª Jornada de Escuela de Alcaldes" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="border-light mb-3 centrado" role="alert">
            <h5>1ª JORNADA DE LA ESCUELA DE ALCALDES 2024</h5>
            <h6><i>Promoción del emprendimiento y del bien común</i></h6>
            <hr></hr>
            <h6><strong>Fecha:</strong> 30 de mayo de 2024</h6>
            <h6><strong>Lugar:</strong> Miranda de Azán, Salamanca. Salón de actos municipal</h6>
            <h6><strong>Horario:</strong> de 09:30 a 17:30</h6>
            <h6><strong>Plazo:</strong> 29 de mayo de 2024</h6>
            </div>
          </div>
        </div>
        <div className="card text-white w-100 caracteristicas colorfondo">
          <div className="card-body">
            <h5 className="card-title"><i class="fas fa-hand-point-right"></i>&nbsp;&nbsp;ASISTENCIA GRATUITA&nbsp;&nbsp;<i class="fas fa-hand-point-left"></i></h5>
            <a href="#form"><p><strong>RESERVA TU PLAZA</strong></p></a>
            <p>Inscripciones hasta el lunes 29 de mayo a las 14:00 h.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card border-secondary w-100 caracteristicas izquierda">
              <div className="card-body">
                <h5 className="card-title centrado">Agenda:</h5>
                <li><strong className='colortext'>9:30 h.- </strong> Recepción y acreditación de participantes.</li>
                <li><strong className='colortext'>9:45 h.- </strong> Saludo de autoridades.</li>
                <li><strong className='colortext'>10:00 h.- </strong>La dinamización sostenible de los tesoros naturales de Miranda de Azán desde la participación y la economía del bien común. Premio Fuentes Claras 2023.
                  <ul>
                    <p><i>José Luis Sánchez Martín, Alcalde de Miranda de Azán.</i></p>
                    <p><i>Pablo Labajos Martín, consultor en sostenibilidad.</i></p>
                  </ul>
                </li>
                <li><strong className='colortext'>11:00 - 11:30 h.- </strong> Pausa para el café.
                  <ul>
                    <p>Restaurante “La Fragua de Ros”: C. Norte 15, Miranda de Azán.</p>
                  </ul>
                </li>
                <li><strong className='colortext'>11:30 - 12:30 h.- </strong> Conversatorios de aldea para buscar emprendedores locales.
                  <ul>
                    <p><i>D. Noemí Barrientos Herrero, León Startup y Manda-Venancia.</i></p>
                  </ul>
                </li>
                <li><strong className='colortext'>12:30 - 13:30 h.- </strong> Feria para el relevo generacional de negocios rurales.
                  <ul>
                    <p><i>D. Charo Mayoral González, Técnico de la Diputación de Salamanca.</i></p>
                  </ul>
                </li>
                <li><strong className='colortext'>13:30 - 14:00 h.- </strong> Dinamización cultural con el Festival “Cuca Cuatro Caminos”.
                  <ul>
                    <p>D. Javier Montes, Programador del Festival Cuca Cuatro Caminos.</p>
                  </ul>
                </li>
                <li><strong className='colortext'>14:00 h.- </strong> Evaluación y clausura.
                </li>
                <li><strong className='colortext'>14:20 h.- </strong> Comida <i>(17 €/persona).</i>
                </li>
                <li><strong className='colortext'>16:30 h.- </strong> Visita al proyecto premiado del Ayuntamiento de Miranda de Azán. <i>(Se aconseja calzado cómodo)</i>
                </li>
              </div>
            </div>
          </div>
        </div>
        <div className="row inscripcion" id="formulario">
          <div className="col-12">
            <div className="alert alert-light text-center" role="alert" id="form">
              <h3>Formulario de inscripción</h3>
              <hr />
              <iframe src="//cursos.itagraformacion.com/form/1692" width="95%" height="1150px" title='formulario inscripción'><p>Tu navegador no admite iframes.</p></iframe>
            </div>
          </div>
        </div>

        <div className='container-fluid'>
          <div className="row">
            <div className="col-12 info">
              Más información <i class="fas fa-phone-square-alt"></i>
              &nbsp;979 10 83 03&nbsp;
              <i className="fas fa-paper-plane"></i>&nbsp;inscripciones@itagraformacion.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
